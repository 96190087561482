import clsx from 'classnames'
import { ReactNode } from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'

export interface FormProps<TFieldValues extends FieldValues> {
  children?: ReactNode
  className?: string
  form: UseFormReturn<TFieldValues>
}

export const Form = <TFieldValues extends FieldValues>({
  children,
  className,
  form,
}: FormProps<TFieldValues>) => {
  return (
    <div className={clsx(className)}>
      <FormProvider {...form}>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            return false
          }}
        >
          {children}
        </form>
      </FormProvider>
    </div>
  )
}
