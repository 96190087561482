import * as React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { AccordionSubtitle } from '@components/AccordionSubtitle'
import { CountsSelector } from '@components/CountsSelector'
import { GridControlsWrapper } from '@components/GuestAccordion/GridControlsWrapper'
import SelectField, { SelectFieldOption } from '@components/SelectField'
import { getYupValidationErrorTranslationKeyForGuest } from '../../forms/guests/validation'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'

const MAX_CHILDREN = 10

const getAgeOptions = (): SelectFieldOption[] => {
  const options: SelectFieldOption[] = Array.from({ length: 18 }, (_, i) => ({
    name: (
      <FormattedMessage
        defaultMessage="{count, plural, =0 {# years} one {# year} other {# years}}"
        description="Years plural"
        values={{ count: i }}
      />
    ),
    value: i.toString(),
  }))

  options.unshift({
    name: (
      <FormattedMessage
        defaultMessage="Select Age"
        description="Empty option for select box in Kids age"
      />
    ),
    value: '',
  })

  return options
}

export const Children = () => {
  const intl = useIntl()
  const { control } = useFormContext()
  const { append, fields, remove } = useFieldArray({ control, name: 'kidsAge' })

  const childrenCount = useBookingFormStore((state) => state.childrenCount)
  const changeChildrenCount = useBookingFormStore((state) => state.changeChildrenCount)

  const options = getAgeOptions()

  const handleAppendKid = () => {
    const index = fields.length + 1
    append({
      id: index,
    })
  }

  const handleDeleteKid = () => {
    if (fields.length) {
      const index = fields.length - 1
      remove(index)
    }
  }

  return (
    <>
      <AccordionSubtitle>
        <FormattedMessage defaultMessage="Children" description="Children subtitle" />
      </AccordionSubtitle>
      <GridControlsWrapper className="mb-4 md:mb-0">
        <div className="px-2 py-3">
          <Controller
            control={control}
            name="kidsCount"
            render={({ field, fieldState }) => (
              <CountsSelector
                {...field}
                error={getYupValidationErrorTranslationKeyForGuest(intl, fieldState.error?.message)}
                heading={
                  <FormattedMessage
                    defaultMessage="{count, plural, =0 {# kids} one {# kid} other {# kids}}"
                    description="Children plural count selector description"
                    values={{ count: childrenCount }}
                  />
                }
                maxCount={10}
                onDecrement={() => {
                  const newCount = childrenCount === 1 ? 0 : childrenCount - 1
                  changeChildrenCount(newCount)
                  field.onChange(newCount)
                  handleDeleteKid()
                }}
                onIncrement={() => {
                  if (childrenCount < MAX_CHILDREN) {
                    const newCount = childrenCount + 1
                    changeChildrenCount(newCount)
                    field.onChange(newCount)
                    handleAppendKid()
                  }
                }}
                value={childrenCount}
              />
            )}
          />
        </div>
        <div className="hidden sm:block bg-transparent"></div>
      </GridControlsWrapper>
      {fields.length > 0 && (
        <>
          <AccordionSubtitle>
            <FormattedMessage defaultMessage="Kids age" description="Children age" />
          </AccordionSubtitle>
          <GridControlsWrapper className="mb-4 md:mb-0">
            {fields.map((field, index) => (
              <div className="px-2 py-3" key={`controller-kidsAge-${index}.age-div-wrapper`}>
                <Controller
                  control={control}
                  key={`controller-kidsAge-${index}.age`}
                  name={`kidsAge.${index}.age`}
                  render={({ field, fieldState }) => {
                    const { onChange, value } = field

                    return (
                      <SelectField
                        {...field}
                        className="w-full md:mr-2"
                        error={getYupValidationErrorTranslationKeyForGuest(
                          intl,
                          fieldState.error?.message
                        )}
                        label={
                          <FormattedMessage
                            defaultMessage="{count, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Kid"
                            description="Count kids in guests selection"
                            values={{ count: index + 1 }}
                          />
                        }
                        onChange={(event) => {
                          onChange(event)
                        }}
                        options={options}
                        value={value?.toString()}
                      />
                    )
                  }}
                />
                <div className="hidden sm:block bg-transparent"></div>
              </div>
            ))}
          </GridControlsWrapper>
        </>
      )}
    </>
  )
}
