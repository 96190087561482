import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccordion } from '../../hooks/useAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useRoomTypeAccordion } from '../../hooks/useRoomTypeAccordion'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { AccordionEnum, IRoomType } from '../../store/data'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import RoomTypeName from './RoomTypeName'
import RoomTypes from './RoomTypes'

const RoomTypeAccordion: React.FunctionComponent = () => {
  const accordion = useBookingFormStore((state) => state.accordion)
  const isFetching = useBookingFormStore((state) => state.isFetching)
  const hotel = useBookingFormStore((state) => state.hotel)
  const visitType = useBookingFormStore((state) => state.visitType)
  const guest = useBookingFormStore((state) => state.guest)
  const roomType = useBookingFormStore((state) => state.roomType)
  const setRoomTypeDialogData = useBookingFormStore((state) => state.setRoomTypeDialogData)

  const { routerQueryParams } = useQueryParams()
  const { onChangeAccordion } = useAccordion()
  const { onChangeRoomType } = useRoomTypeAccordion()

  const onLoadRoomType = (value: IRoomType | null) => setRoomTypeDialogData(value)

  const isLoading = !!routerQueryParams.roomType && isFetching
  const isActive = isLoading || !!roomType
  const isDisabled = !hotel || !visitType

  return (
    <Accordion
      active={isActive}
      disabled={isDisabled}
      isLoading={isLoading}
      onClear={() => onChangeRoomType(null)}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.ROOMS)}
      open={accordion === AccordionEnum.ROOMS}
      title={
        roomType ? (
          <RoomTypeName roomType={roomType} />
        ) : (
          <AccordionTitle isLoading={isLoading}>
            <FormattedMessage
              defaultMessage="Room Category"
              description="Room category accordion title"
            />
          </AccordionTitle>
        )
      }
    >
      <RoomTypes
        hotel={hotel}
        numberOfBeds={guest}
        onLoadRoomType={onLoadRoomType}
        onSelect={onChangeRoomType}
        selectedRoomType={roomType}
        visitType={visitType}
      />
    </Accordion>
  )
}

export default RoomTypeAccordion
