import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { IconButton, Tooltip } from '@mui/material'
import clsx from 'classnames'
import * as React from 'react'
import { forwardRef, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import BasicButton, { BasicButtonProps } from '@components/BasicButton'
import styles from '@components/CountsSelector.module.scss'
import Typography from '@components/Typography'

interface CountsSelectorProps extends BasicButtonProps {
  error?: React.ReactNode
  heading: ReactNode
  maxCount?: number
  onDecrement: () => void
  onIncrement: () => void
  value: number
}

export const CountsSelector = forwardRef<HTMLInputElement, CountsSelectorProps>(
  ({ error = null, heading, maxCount, onDecrement, onIncrement, value, ...rest }, ref) => {
    const selected = value > 0

    return (
      <>
        <div ref={ref}>
          <BasicButton
            {...rest}
            className={clsx(styles.root, {
              [styles.rootSelected]: selected,
            })}
            clearBasicStyle
          >
            <div className="flex justify-between items-center w-full px-2">
              <Tooltip
                title={
                  value === 0 ? (
                    <FormattedMessage
                      defaultMessage="Minimum number"
                      description="Minimum number info tooltip for count selector"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Decrease"
                      description="Decrease count tooltip"
                    />
                  )
                }
              >
                <div>
                  <IconButton disabled={value === 0} onClick={onDecrement} size="small">
                    <RemoveIcon style={{ color: selected ? '#FFF' : 'currentColor' }} />
                  </IconButton>
                </div>
              </Tooltip>
              <Typography component="span" variant="subcategory">
                {heading}
              </Typography>
              <Tooltip
                title={
                  maxCount && value === maxCount ? (
                    <FormattedMessage
                      defaultMessage="Maximum number"
                      description="Maximum number info tooltip for count selector"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Increase"
                      description="Increase count tooltip"
                    />
                  )
                }
              >
                <div>
                  <IconButton disabled={value === maxCount} onClick={onIncrement} size="small">
                    <AddIcon style={{ color: selected ? '#FFF' : 'currentColor' }} />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          </BasicButton>
        </div>
        {error && (
          <div className="absolute text-caption right-0 bottom-0 bg-error text-light rounded-3xl tracking-wider px-2 py-1">
            {error}
          </div>
        )}
      </>
    )
  }
)

CountsSelector.displayName = 'CountsSelector'
