import * as yup from 'yup'
import { IChild, IPet } from '../../store/data'
import { guestsFormValidationCases } from '../guests/validation'
import { useFormWithSchema } from '../useFormWithSchema'

const guestsWithKidsAndPetsFormValidationSchema = yup
  .object()
  .shape({
    guest: yup.number().nullable().required(guestsFormValidationCases.guestsCountRequired),
    kidsAge: yup.array().of(
      yup.object({
        age: yup.number().nullable().required(guestsFormValidationCases.kidAgeRequired),
        id: yup.number().required(),
      })
    ),
    kidsCount: yup.number(),
    petsCount: yup.number(),
    petsSpecies: yup.array().of(
      yup.object({
        id: yup.number().required(),
        species: yup.string().nullable().required(guestsFormValidationCases.petSpeciesRequired),
      })
    ),
  })
  .required()

export type GuestsWithKidsAndPetsFormValues = yup.InferType<
  typeof guestsWithKidsAndPetsFormValidationSchema
>

export const defaultValuesForGuestsWithKidsAndPetsForm = {
  guest: 0,
  kidsAge: [],
  kidsCount: 0,
  petsCount: 0,
  petsSpecies: [],
}

export const useGuestsWithKidsAndPetsForm = (
  guest: number | null,
  children: IChild[],
  pets: IPet[]
) => {
  const guestsWithKidsAndPetsForm = useFormWithSchema<GuestsWithKidsAndPetsFormValues>(
    guestsWithKidsAndPetsFormValidationSchema,
    {
      context: { isDemand: false },
      defaultValues: {
        ...defaultValuesForGuestsWithKidsAndPetsForm,
        guest: guest || 0,
        kidsAge: children,
        petsSpecies: pets,
      },
    }
  )

  return guestsWithKidsAndPetsForm
}
