import * as React from 'react'
import Card from '../Card'
import Skeleton from '../Skeleton'

const RoomTypeSkeleton = () => (
  <Card
    description={
      <div className="mt-1">
        <Skeleton inline width={150} />
      </div>
    }
    onSelect={() => {}}
    selected={false}
    title={<Skeleton inline width={150} />}
    variant="loading"
  />
)

export default RoomTypeSkeleton
