import clsx from 'classnames'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { AccordionSubtitle } from '@components/AccordionSubtitle'
import { GridControlsWrapper } from '@components/GuestAccordion/GridControlsWrapper'
import { getYupValidationErrorTranslationKeyForGuest } from '../../forms/guests/validation'
import SelectButton from '../SelectButton'
import styles from './Guests.module.scss'

const Guests = () => {
  const intl = useIntl()

  const { control } = useFormContext()

  return (
    <>
      <AccordionSubtitle>
        <FormattedMessage defaultMessage="Adults" description="Adults subtitle" />
      </AccordionSubtitle>
      <GridControlsWrapper className="mb-4 md:mb-0">
        <Controller
          control={control}
          name="guest"
          render={({ field: { onChange, value }, fieldState }) => {
            const error = getYupValidationErrorTranslationKeyForGuest(
              intl,
              fieldState.error?.message
            )

            return (
              <>
                <div className="px-2 py-3">
                  <SelectButton
                    className={styles.rootItem}
                    error={error}
                    heading={
                      <FormattedMessage
                        defaultMessage="1 Guest"
                        description="One Guest in Guests component"
                      />
                    }
                    onClick={() => onChange(1)}
                    selected={value === 1}
                  />
                </div>
                <div className="px-2 py-3">
                  <SelectButton
                    className={clsx(styles.rootItem)}
                    error={error}
                    heading={
                      <FormattedMessage
                        defaultMessage="2 Guests"
                        description="Two Guest in Guests component"
                      />
                    }
                    onClick={() => onChange(2)}
                    selected={value === 2}
                  />
                </div>
              </>
            )
          }}
        />
      </GridControlsWrapper>
    </>
  )
}

export default Guests
