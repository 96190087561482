import { differenceInDays } from 'date-fns'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { PriceExplanationMark } from '@components/PriceInfo/PriceExplanationMark'
import { getPriceNumber } from '../../helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { IPrice, ITravelDate } from '../../store/data'
import FormattedPrice from '../FormattedPrice'
import Skeleton from '../Skeleton'
import Typography from '../Typography'

type PriceProps = {
  isFinalPrice?: boolean
  lowestPrice?: IPrice | null
  price?: IPrice | null
  travelDate: ITravelDate
  withPriceExplanation?: boolean
}

const Price: React.FunctionComponent<PriceProps> = ({
  isFinalPrice,
  lowestPrice,
  price,
  travelDate,
  withPriceExplanation = false,
}) => {
  const language = useBookingFormStore((state) => state.language)

  return (
    <div className="italic flex flex-col items-end pr-8">
      <Typography className="text-dark font-medium" variant="subcategory">
        {isFinalPrice ? (
          price !== null ? (
            <>
              <FormattedPrice value={getPriceNumber(price, 'amount', language)} />
              {withPriceExplanation && <PriceExplanationMark />}
            </>
          ) : (
            <Skeleton height={24} inline width={80} />
          )
        ) : (
          <>
            <span className="mr-1">
              <FormattedMessage
                defaultMessage="from"
                description="From price on Lowest price component"
              />
            </span>
            <FormattedPrice
              value={lowestPrice ? getPriceNumber(lowestPrice, 'amount_per_night', language) : 0}
            />
            {withPriceExplanation && <PriceExplanationMark />}
          </>
        )}
      </Typography>
      <Typography className="text-muted" variant="small">
        {isFinalPrice && travelDate.from && travelDate.to ? (
          <FormattedMessage
            defaultMessage="{count, plural, one {for # night} other {for # nights}}"
            description="Count nights in Booking details"
            values={{ count: differenceInDays(travelDate.to, travelDate.from) }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="/ night"
            description="night unit in Lowest price component"
          />
        )}
      </Typography>
    </div>
  )
}

export default Price
