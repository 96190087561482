import clsx from 'classnames'
import * as React from 'react'
import { ReactNode } from 'react'
import styles from './AccordionSubtitle.module.scss'
import Typography from './Typography'

type AccordionSubtitleProps = {
  children: ReactNode
  className?: string
}

export const AccordionSubtitle = ({ children, className = undefined }: AccordionSubtitleProps) => (
  <div className="md:row-span-1 font-bold pl-2 md:pl-8">
    <div className={styles.root}>
      <Typography
        className={clsx(styles.rootContent, className)}
        component="h3"
        variant="subcategory"
      >
        {children}
      </Typography>
    </div>
  </div>
)
