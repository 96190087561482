import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { resolveNextAccordion } from '@utils/accordion'
import { getLang } from '../../helpers'
import { useAccordion } from '../../hooks/useAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useVisitTypeCategoriesAccordion } from '../../hooks/useVisitTypeCategoriesAccordion'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { AccordionEnum, IVisitCategory, IVisitType } from '../../store/data'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import VisitTypeCategories from './VisitTypeCategories'

const VisitTypeCategoriesAccordion: React.FunctionComponent = () => {
  const language = useBookingFormStore((state) => state.language)
  const accordion = useBookingFormStore((state) => state.accordion)
  const destination = useBookingFormStore((state) => state.destination)
  const guest = useBookingFormStore((state) => state.guest)
  const hotel = useBookingFormStore((state) => state.hotel)
  const roomType = useBookingFormStore((state) => state.roomType)
  const visitCategories = useBookingFormStore((state) => state.visitCategories)
  const visitTypeCategory = useBookingFormStore((state) => state.visitTypeCategory)
  const isFetching = useBookingFormStore((state) => state.isFetching)
  const visitType = useBookingFormStore((state) => state.visitType)
  const changeVisitTypeCategory = useBookingFormStore((state) => state.changeVisitTypeCategory)
  const setVisitTypeDialogData = useBookingFormStore((state) => state.setVisitTypeDialogData)

  const { routerQueryParams } = useQueryParams()
  const { onChangeAccordion } = useAccordion()
  const { onChangeVisitType } = useVisitTypeCategoriesAccordion()

  const onLoadVisitType = (value: IVisitType | null) => {
    setVisitTypeDialogData(value)
  }

  const onChangeVisitTypeCategory = (value: IVisitCategory | null) => {
    if (value) {
      const nextAccordion = resolveNextAccordion(accordion as AccordionEnum, {
        destination: !!destination,
        guest: !!guest,
        hotel: !!hotel,
        roomType: !!roomType,
        visitType: !!visitType,
      })
      onChangeAccordion(nextAccordion)
    }
    changeVisitTypeCategory(value)
  }

  const isLoading = !!routerQueryParams.visitType && isFetching
  const isActive = isLoading || !!visitType
  const isDisabled = !hotel

  const visitTypeTitle: React.ReactNode = React.useMemo(() => {
    if (visitType) {
      return getLang(visitType?.name, language)
    } else {
      return (
        <FormattedMessage
          defaultMessage="Type of Stay"
          description="Type of Stay accordion title with unknown type"
        />
      )
    }
  }, [visitType, language])

  return (
    <Accordion
      active={isActive}
      disabled={isDisabled}
      isLoading={isLoading}
      onClear={async () => {
        onChangeVisitTypeCategory(null)
        await onChangeVisitType(null)
      }}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.TYPE_OF_STAY)}
      open={accordion === AccordionEnum.TYPE_OF_STAY}
      title={<AccordionTitle isLoading={isLoading}>{visitTypeTitle}</AccordionTitle>}
    >
      <VisitTypeCategories
        hotelId={hotel?.id ?? null}
        key={`${visitType?.id}-${visitTypeCategory?.id}-${hotel?.id}`}
        onLoadVisitType={onLoadVisitType}
        onSelectVisitType={onChangeVisitType}
        onSelectVisitTypeCategory={onChangeVisitTypeCategory}
        selectVisitType={visitType}
        selectVisitTypeCategory={visitTypeCategory}
        visitCategories={visitCategories}
      />
    </Accordion>
  )
}

export default VisitTypeCategoriesAccordion
