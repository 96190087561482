import * as React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { AccordionSubtitle } from '@components/AccordionSubtitle'
import { CountsSelector } from '@components/CountsSelector'
import { GridControlsWrapper } from '@components/GuestAccordion/GridControlsWrapper'
import SelectField, { SelectFieldOption } from '@components/SelectField'
import { getYupValidationErrorTranslationKeyForGuest } from '../../forms/guests/validation'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'

const MAX_PETS = 3

const getPetSpeciesOptions = (): SelectFieldOption[] => {
  const options: SelectFieldOption[] = [
    {
      name: <FormattedMessage defaultMessage="Dog" description="Dog title" />,
      value: 'pes',
    },
    {
      name: <FormattedMessage defaultMessage="Cat" description="Cat title" />,
      value: 'kočka',
    },
    {
      isOther: true,
      name: <FormattedMessage defaultMessage="Other" description="Other pet title" />,
      value: 'others',
    },
  ]

  options.unshift({
    name: (
      <FormattedMessage
        defaultMessage="Select pet type"
        description="Empty option for select box in Pets species"
      />
    ),
    value: '',
  })

  return options
}

export const Pets = () => {
  const intl = useIntl()
  const { control } = useFormContext()
  const { append, fields, remove } = useFieldArray({ control, name: 'petsSpecies' })

  const petsCount = useBookingFormStore((state) => state.petsCount)
  const changePetsCount = useBookingFormStore((state) => state.changePetsCount)

  const options = getPetSpeciesOptions()

  const handleAppendPet = () => {
    const index = fields.length + 1
    append({
      id: index,
    })
  }

  const handleDeletePet = () => {
    if (fields.length) {
      const index = fields.length - 1
      remove(index)
    }
  }

  return (
    <>
      <AccordionSubtitle>
        <FormattedMessage defaultMessage="Pets" description="Pets subtitle" />
      </AccordionSubtitle>
      <GridControlsWrapper>
        <div className="px-2 py-3">
          <Controller
            control={control}
            name="petsCount"
            render={({ field, fieldState }) => (
              <CountsSelector
                {...field}
                error={getYupValidationErrorTranslationKeyForGuest(intl, fieldState.error?.message)}
                heading={
                  <FormattedMessage
                    defaultMessage="{count, plural, =0 {# pets} one {# pet} other {# pets}}"
                    description="Pets plural count selector description"
                    values={{ count: petsCount }}
                  />
                }
                maxCount={3}
                onDecrement={() => {
                  const newCount = petsCount === 1 ? 0 : petsCount - 1
                  changePetsCount(newCount)
                  field.onChange(newCount)
                  handleDeletePet()
                }}
                onIncrement={() => {
                  if (petsCount < MAX_PETS) {
                    const newCount = petsCount + 1
                    changePetsCount(newCount)
                    field.onChange(newCount)
                    handleAppendPet()
                  }
                }}
                value={petsCount}
              />
            )}
          />
        </div>
        <div className="hidden sm:block bg-transparent"></div>
      </GridControlsWrapper>
      {fields.length > 0 && (
        <>
          <AccordionSubtitle className="mt-4 md:mt-0">
            <FormattedMessage defaultMessage="Pet types" description="Pet species label" />
          </AccordionSubtitle>
          <GridControlsWrapper>
            {fields.map((field, index) => (
              <div
                className="px-2 py-3"
                key={`controller-petsSpecies-${index}.species-div-wrapper`}
              >
                <Controller
                  control={control}
                  key={`controller-petsSpecies-${index}.species`}
                  name={`petsSpecies.${index}.species`}
                  render={({ field, fieldState }) => {
                    const { onChange } = field

                    return (
                      <SelectField
                        {...field}
                        className="w-full md:mr-2"
                        error={getYupValidationErrorTranslationKeyForGuest(
                          intl,
                          fieldState.error?.message
                        )}
                        label={
                          <FormattedMessage
                            defaultMessage="{count, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Pet"
                            description="Count pets in guests selection"
                            values={{ count: index + 1 }}
                          />
                        }
                        onChange={(event) => {
                          onChange(event)
                        }}
                        options={options}
                      />
                    )
                  }}
                />
                <div className="hidden sm:block bg-transparent"></div>
              </div>
            ))}
          </GridControlsWrapper>
        </>
      )}
    </>
  )
}
