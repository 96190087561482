import * as React from 'react'
import { useEffect } from 'react'
import RoomTypeSkeleton from '@components/RoomTypeAccordion/RoomTypeSkeleton'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import {
  Identificator,
  IHotel,
  IHotelDetailParamsQuery,
  IRoomType,
  IVisitType,
} from '../../store/data'
import TwoColumnsGrid from '../TwoColumnsGrid'
import RoomTypeItem from './RoomTypeItem'

type RoomTypesProps = {
  hotel: IHotel | null
  numberOfBeds: number | null
  onLoadRoomType: (data: IRoomType) => void
  onSelect: (roomType: IRoomType) => void
  selectedRoomType?: IRoomType | null
  visitType: IVisitType | null
}

const RoomTypes: React.FunctionComponent<RoomTypesProps> = ({
  hotel,
  numberOfBeds,
  onLoadRoomType,
  onSelect,
  selectedRoomType = null,
  visitType,
}) => {
  const roomTypes = useBookingFormStore((state) => state.roomTypes)

  const isFetchingHotelRoomTypes = useBookingFormStore((state) => state.isFetchingHotelRoomTypes)
  const setLoadingImages = useBookingFormStore((state) => state.setLoadingImages)
  const loadHotelRoomTypes = useBookingFormStore((state) => state.loadHotelRoomTypes)

  useEffect(() => {
    const fetchHotelRoomTypes = async (hotelId: Identificator, params: IHotelDetailParamsQuery) => {
      await loadHotelRoomTypes(hotelId, params)
    }

    if (hotel?.id && visitType?.id && numberOfBeds && numberOfBeds > 0) {
      fetchHotelRoomTypes(hotel.id, {
        visit_type_id: visitType.id,
      })
    }
  }, [hotel?.id, visitType?.id, numberOfBeds])

  React.useEffect(() => {
    const totalImageCount = (roomTypes || []).filter((roomType) => !!roomType.image).length
    setLoadingImages(totalImageCount)
  }, [roomTypes, setLoadingImages])

  return (
    <TwoColumnsGrid>
      {!isFetchingHotelRoomTypes &&
        (roomTypes || []).map((item) => (
          <RoomTypeItem
            data={item}
            key={item.id}
            onLoadRoomType={() => onLoadRoomType(item)}
            onSelect={() => onSelect(item)}
            selected={item?.id === selectedRoomType?.id}
          />
        ))}
      {isFetchingHotelRoomTypes &&
        Array.from(Array(6).keys()).map((item) => <RoomTypeSkeleton key={item} />)}
    </TwoColumnsGrid>
  )
}

export default RoomTypes
