import clsx from 'classnames'
import { ReactNode } from 'react'

interface GridControlsWrapperProps {
  children: ReactNode
  className?: string
}

export const GridControlsWrapper = ({
  children,
  className = undefined,
}: GridControlsWrapperProps) => {
  return <div className={clsx('grid grid-cols-1 sm:grid-cols-2', className)}>{children}</div>
}
