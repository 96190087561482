import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldValues,
  useForm as hookFormUseForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

export const useFormWithSchema = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
  schema: AnyObjectSchema,
  useFormProps?: UseFormProps<TFieldValues, TContext>
): UseFormReturn<TFieldValues> => {
  const form = hookFormUseForm<TFieldValues>({ mode: 'all', resolver: yupResolver(schema), ...useFormProps })

  return form;
}
