import clsx from 'classnames'
import * as React from 'react'
import { useIntl } from 'react-intl'
import styles from './SelectField.module.scss'
import Typography from './Typography'

export interface SelectFieldOption {
  isOther?: boolean
  name: React.ReactNode
  value: string | number
}

export interface SelectFieldProps
  extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'onChange' | 'value' | 'onBlur'> {
  className?: string
  error?: React.ReactNode
  label: React.ReactNode
  name: string
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
  onChange?: (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | string | null | undefined
  ) => void
  options: SelectFieldOption[]
  value?: string
}

const SelectField = React.forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    { className, error = null, label, name, onBlur, onChange, options = [], value, ...rest },
    ref
  ) => {
    const { formatMessage } = useIntl()
    const hasError = Boolean(error)
    const id = React.useId()
    const [showCustomInput, setShowCustomInput] = React.useState(false)

    const handleCustomInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e as any)
      }
    }

    React.useEffect(() => {
      const selectedOption = options.find((opt) => `${opt.value}` === `${value}`)
      const isOtherSelected = Boolean(selectedOption?.isOther)
      const hasCustomValue =
        value != null && value !== '' && typeof value !== undefined && !selectedOption

      setShowCustomInput(isOtherSelected || hasCustomValue)
    }, [options, value])

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = e.target.value
      const selectedOption = options.find((opt) => `${opt.value}` === `${selectedValue}`)

      if (selectedOption?.isOther) {
        setShowCustomInput(true)
        onChange?.(undefined)
      } else {
        setShowCustomInput(false)
        onChange?.(selectedValue)
      }
    }

    const handleCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value)
    }

    const handleClearCustomValue = () => {
      setShowCustomInput(false)
      onChange?.(undefined)
    }

    return (
      <div className={clsx(styles.root, { [styles.rootHasError]: hasError }, className)}>
        <label className={styles.rootLabel} htmlFor={id}>
          {label}
        </label>
        <div className="relative flex gap-2">
          {!showCustomInput && (
            <select
              {...rest}
              className={styles.rootSelect}
              id={id}
              name={name}
              onChange={handleSelectChange}
              ref={ref}
              value={value || ''}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          )}
          {showCustomInput && (
            <div className="relative flex-1">
              <input
                className={clsx(styles.rootSelect, 'pr-8')}
                onBlur={handleCustomInputBlur}
                onChange={handleCustomInputChange}
                placeholder={formatMessage({
                  defaultMessage: 'Enter custom value',
                  description: 'Enter custom value placeholder in SelectField',
                })}
                type="text"
                value={value || undefined}
              />
              <button
                className="absolute right-2 top-1/2 -translate-y-1/2 text-small mr-2"
                onClick={handleClearCustomValue}
                type="button"
              >
                ✕
              </button>
            </div>
          )}
        </div>
        {hasError && (
          <Typography className={styles.rootError} component="span" variant="caption">
            {error}
          </Typography>
        )}
      </div>
    )
  }
)

SelectField.displayName = 'SelectField'

export default SelectField
