import clsx from 'classnames'
import * as React from 'react'
import Skeleton from '@components/Skeleton'
import { useIsAllBookingParams } from '../../hooks/useIsAllBookingParams'
import { useIsChildrenOrPets } from '../../hooks/useIsChildrenOrPets'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import BookingDetails from '../BookingDetails'
import { useAllBookingParams } from './helpers'
import LowestPrice from './LowestPrice'
import Price from './Price'
import styles from './PriceInfo.module.scss'
import ReservationButtons from './ReservationButtons'

const PriceInfo: React.FunctionComponent = () => {
  const { lowestPrice, onBookingRequest, onlyAvailableDemand, price } = useAllBookingParams()
  const isAllBookingParams = useIsAllBookingParams()
  const isChildrenOrPets = useIsChildrenOrPets()

  const appInitialized = useBookingFormStore((state) => state.appInitialized)
  const fetching = useBookingFormStore((state) => state.isFetching)
  const datesFetching = useBookingFormStore((state) => state.calendar.isFetching)

  const isDataFetching = datesFetching || fetching

  return (
    <div
      className={clsx(styles.root, {
        [styles.rootIsValid]: isAllBookingParams,
      })}
    >
      <div className={styles.rootWrapper}>
        {isDataFetching ? (
          <Skeleton containerClassName="flex items-center" height={30} width={200} />
        ) : (
          <>
            {isAllBookingParams ? (
              <Price data={price} withPriceExplanation={isChildrenOrPets} />
            ) : (
              <LowestPrice data={lowestPrice} withPriceExplanation={isChildrenOrPets} />
            )}
          </>
        )}
        <BookingDetails />
      </div>
      {appInitialized && (
        <ReservationButtons
          disabled={!isAllBookingParams}
          onBookingRequest={onBookingRequest}
          onlyAvailableDemand={onlyAvailableDemand || isChildrenOrPets}
        />
      )}
    </div>
  )
}

export default PriceInfo
