import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { PriceBreakdown } from '@components/PriceInfo/PriceBreakdown'
import { PriceExplanationMark } from '@components/PriceInfo/PriceExplanationMark'
import { getPriceNumber } from '../../helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { IPrice } from '../../store/data'
import FormattedPrice from '../FormattedPrice'
import Typography from '../Typography'
import styles from './Price.module.scss'

type PriceProps = {
  data: IPrice | null
  withPriceExplanation?: boolean
}

const Price: React.FunctionComponent<PriceProps> = ({ data, withPriceExplanation = false }) => {
  const language = useBookingFormStore((state) => state.language)
  const step = useBookingFormStore((state) => state.stepper.step)

  const showPriceBreakdown = step === 4

  return (
    <div className={styles.root}>
      {!showPriceBreakdown ? (
        <>
          <Typography className={styles.rootPrice} component="span" variant="category">
            <FormattedPrice value={data ? getPriceNumber(data, 'amount', language) : 0} />
            {withPriceExplanation && <PriceExplanationMark />}
          </Typography>
          <Typography className={styles.rootDescription} component="span">
            <FormattedMessage defaultMessage="(incl. VAT)" description="Text in Price component" />
          </Typography>
        </>
      ) : (
        <PriceBreakdown data={data} />
      )}
    </div>
  )
}

export default Price
