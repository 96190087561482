import * as React from 'react'
import { useMemo } from 'react'
import {
  getVisitTypesFilter,
  getVisitTypesForHotelFilter,
} from '@components/VisitTypeCategoriesAccordion/helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { Identificator, IVisitCategory, IVisitType } from '../../store/data'
import VisitTypeCategory from './VisitTypeCategory'
import VisitTypeCategorySkeleton from './VisitTypeCategorySkeleton'

type VisitTypesCategoriesProps = {
  hotelId: Identificator | null
  onLoadVisitType: (type: IVisitType) => void
  onSelectVisitType: (visitType: IVisitType) => Promise<void>
  onSelectVisitTypeCategory: (visitType: IVisitCategory) => void
  selectVisitType?: IVisitType | null
  selectVisitTypeCategory?: IVisitCategory | null
  visitCategories: IVisitCategory[]
}

const VisitTypesCategories: React.FunctionComponent<VisitTypesCategoriesProps> = ({
  hotelId,
  onLoadVisitType,
  onSelectVisitType,
  onSelectVisitTypeCategory,
  selectVisitType = null,
  selectVisitTypeCategory = null,
  visitCategories,
}) => {
  const language = useBookingFormStore((state) => state.language)
  const isFetchingVisitCategories = useBookingFormStore((state) => state.isFetchingVisitCategories)

  const data = useMemo(() => {
    if (visitCategories.length === 0) return []

    const visitCategoriesToFilter = [...visitCategories]

    const validCategories = visitCategoriesToFilter
      .filter((visitCategories) => visitCategories.visitTypes.length > 0)
      .filter((visitCategories) => {
        const filteredVisitTypesForHotel = getVisitTypesForHotelFilter(
          visitCategories.visitTypes,
          hotelId
        )

        return filteredVisitTypesForHotel.length > 0
      })
      .filter(
        (visitCategories) => getVisitTypesFilter(visitCategories.visitTypes, language).length > 0
      )

    // filter only valid visit types from categories (by hotel available)
    const validCategoriesWithVisitTypes = validCategories.map((visitCategory) => {
      const { visitTypes } = visitCategory
      return {
        ...visitCategory,
        visitTypes: getVisitTypesForHotelFilter(visitTypes, hotelId),
      }
    })

    return validCategoriesWithVisitTypes
  }, [hotelId, selectVisitType?.id, language, visitCategories.length])

  return (
    <>
      {data.map((visitCategory) => (
        <VisitTypeCategory
          key={visitCategory.id}
          onLoadVisitType={onLoadVisitType}
          onSelectVisitType={onSelectVisitType}
          onSelectVisitTypeCategory={onSelectVisitTypeCategory}
          selectVisitType={selectVisitType}
          selectVisitTypeCategory={selectVisitTypeCategory}
          visitCategory={visitCategory}
        />
      ))}
      {isFetchingVisitCategories &&
        Array.from(Array(4).keys()).map((item) => <VisitTypeCategorySkeleton key={item} />)}
    </>
  )
}

export default VisitTypesCategories
