import * as React from 'react'
import { useIsChildrenOrPets } from '../../hooks/useIsChildrenOrPets'
import ButtonConfirm from './ButtonConfirm'
import CalendarContent from './CalendarContent'
import CalendarControls from './CalendarControls'
import { useCalendar, useCalendarControls } from './helpers'
import Legend from './Legend'
import Price from './Price'

const Calendar: React.FunctionComponent = () => {
  const {
    checkouts,
    date,
    isFetching,
    isFinalPrice,
    lowestPrice,
    mode,
    onChangeDate,
    onConfirm,
    onSelectDate,
    price,
    travelDate,
  } = useCalendar()
  const { onClickAway } = useCalendarControls()
  const isChildrenOrPets = useIsChildrenOrPets()

  return (
    <div className="calendar" onClick={onClickAway}>
      <CalendarControls date={date} isFetching={isFetching} onChangeDate={onChangeDate} />
      <CalendarContent
        checkouts={checkouts}
        date={date}
        isFetching={isFetching}
        mode={mode}
        onSelectDate={onSelectDate}
        travelDate={travelDate}
      />
      <div className="border-t-2 border-t-card-default my-4" />
      <div className="flex flex-col items-end md:flex-row md:items-center md:justify-between">
        <Legend />
        <div className="flex items-center mt-2 md:mt-0">
          <Price
            isFinalPrice={isFinalPrice}
            lowestPrice={lowestPrice}
            price={price}
            travelDate={travelDate}
            withPriceExplanation={isChildrenOrPets}
          />
          <ButtonConfirm disabled={!(travelDate.from && travelDate.to)} onConfirm={onConfirm} />
        </div>
      </div>
    </div>
  )
}

export default Calendar
